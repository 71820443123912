import React from "react"
import "../assets/vendor/slick-1.5.9/slick/slick.css";
import "../assets/vendor/slick-1.5.9/slick/slick-theme.scss";
import { filterArrayByType, chunk } from '../utility'

const Capability = ({ data }) => {

  console.log("CAPABILITY DATA", data);

  let capabilityToolboxes = filterArrayByType(data.body, "capability_toolbox");
  console.log(capabilityToolboxes);

  return (
    <>
      <div className="inset">
        <div className="row">
          <div className="col-md-6">
            <h2 className="headline margin-bottom-20 headline-mobile">{data.capability_category_title.text}</h2>
          </div>
          <div className="col-md-6">
            <div className="article">
              <p className="beta" style={{ color: '#766467' }}>{data.capability_category_tagline}</p>
              <div dangerouslySetInnerHTML={{ __html: data.capability_category_description.html }}></div>
            </div>
          </div>
        </div>
      </div> {/*  /inset  */}

      <div className="inset margin-bottom-0">
        <h2 className="tag">{data.capabilities_label} </h2>
        <div className="row">
          {
            data.capabilities.map((capability, i) => {
              return (
                <div className="col-md-4 margin-bottom-30" key={i}>
                  <div className="box txt-center capabilities-box">
                    <div className="corner">
                      {
                        ("I").repeat(i + 1)
                      }
                    </div>
                    <p>
                      <img src={capability.capability_image.url} className="img-responsive box-width-defined center-block" alt={capability.capability_image.alt} />
                    </p>
                    <h3 className="delta">{capability.capability_title.text}</h3>
                    <div className="txt-18 txt-gray-85" dangerouslySetInnerHTML={{ __html: capability.capability_description.html }}>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>


      {
        capabilityToolboxes.map((capabilityToolbox, i) => {

          let tools = [];
          if (capabilityToolbox) {
            tools = chunk(capabilityToolbox.items, 3);
          }
          return (
            <div key={i} className="box style-1 margin-bottom-30">
              <h2 className="tag">{capabilityToolbox.primary.capability_toolbox_label.text}</h2>
              <div className="row">
                <div className="col-md-3 txt-18" dangerouslySetInnerHTML={{ __html: capabilityToolbox.primary.capability_toolbox_description.html }}></div>
                <div className="col-md-9">
                  {
                    tools.map((tool, j) => {
                      return (
                        <div className="row list-row" key={j}>
                          {
                            tool.map((item, k) => {
                              return (
                                <div key={k} className="col-sm-4 txt-12" >
                                  <div className="list-item">
                                    {item.capability_tool}
                                  </div>
                                </div>
                              )
                            })
                          }
                        </div>
                      )
                    })
                  }
                </div>

              </div>
            </div>
          )
        })
      }
    </>
  )
}


export default Capability;
