export function filterArrayByType(body, filterString) {
	return (
		body ? body.filter(d => d.slice_type && d.slice_type.replace(/\d+$/, '') === filterString) : []
	);
}

export function hasSlice(body, filterString) {
	return filterArrayByType(body, filterString).length > 0;
}

export function chunk(array, size) {
	const chunked_arr = [];
	let copied = [...array]; // ES6 destructuring
	const numOfChild = Math.ceil(copied.length / size); // Round up to the nearest integer
	for (let i = 0; i < numOfChild; i++) {
	  chunked_arr.push(copied.splice(0, size));
	}
	return chunked_arr;
}

/**
 * Calls the necessary scripts to load a cognito form
 * @param {number} formId - the form id from the "publish" code in Cognito.
 */
export function renderCognitoForm(formId) {
  const script = document.createElement("script");
  script.onload = () => {
    window.Cognito.load("forms", { id: formId });
  };
  script.src = `https://www.cognitoforms.com/s/7P8mNzgBHkmvwCsl6uKJqw`;
  document.body.appendChild(script);
}